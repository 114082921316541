@mixin combined($path){


  @font-face {
    font-family: 'smileyface';
    src: url('#{$path}/smileyface/smileyface-regularitalic-webfont.eot');
    src: url('#{$path}/smileyface/smileyface-regularitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$path}/smileyface/smileyface-regularitalic-webfont.woff2') format('woff2'),
    url('#{$path}/smileyface/smileyface-regularitalic-webfont.woff') format('woff'),
    url('#{$path}/smileyface/smileyface-regularitalic-webfont.ttf') format('truetype'),
    url('#{$path}/smileyface/smileyface-regularitalic-webfont.svg#smiley_faceitalic') format('svg');
    font-weight: normal;
    font-style: italic;
  }


  @font-face {
    font-family: 'smileyface';
    src: url('#{$path}/smileyface/smileyface-regular-webfont.eot');
    src: url('#{$path}/smileyface/smileyface-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$path}/smileyface/smileyface-regular-webfont.woff2') format('woff2'),
    url('#{$path}/smileyface/smileyface-regular-webfont.woff') format('woff'),
    url('#{$path}/smileyface/smileyface-regular-webfont.ttf') format('truetype'),
    url('#{$path}/smileyface/smileyface-regular-webfont.svg#smiley_faceregular') format('svg');
    font-weight: normal;
    font-style: normal;

  }


  @font-face {
    font-family: 'smileyface';
    src: url('#{$path}/smileyface/smileyface-mediumitalic-webfont.eot');
    src: url('#{$path}/smileyface/smileyface-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$path}/smileyface/smileyface-mediumitalic-webfont.woff2') format('woff2'),
    url('#{$path}/smileyface/smileyface-mediumitalic-webfont.woff') format('woff'),
    url('#{$path}/smileyface/smileyface-mediumitalic-webfont.ttf') format('truetype'),
    url('#{$path}/smileyface/smileyface-mediumitalic-webfont.svg#smiley_facemedium_italic') format('svg');
    font-weight: 500;
    font-style: italic;

  }


  @font-face {
    font-family: 'smileyface';
    src: url('#{$path}/smileyface/smileyface-lightitalic-webfont.eot');
    src: url('#{$path}/smileyface/smileyface-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$path}/smileyface/smileyface-lightitalic-webfont.woff2') format('woff2'),
    url('#{$path}/smileyface/smileyface-lightitalic-webfont.woff') format('woff'),
    url('#{$path}/smileyface/smileyface-lightitalic-webfont.ttf') format('truetype'),
    url('#{$path}/smileyface/smileyface-lightitalic-webfont.svg#smiley_facelight_italic') format('svg');
    font-weight: 300;
    font-style: italic;

  }


  @font-face {
    font-family: 'smileyface';
    src: url('#{$path}/smileyface/smileyface-medium-webfont.eot');
    src: url('#{$path}/smileyface/smileyface-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$path}/smileyface/smileyface-medium-webfont.woff2') format('woff2'),
    url('#{$path}/smileyface/smileyface-medium-webfont.woff') format('woff'),
    url('#{$path}/smileyface/smileyface-medium-webfont.ttf') format('truetype'),
    url('#{$path}/smileyface/smileyface-medium-webfont.svg#smiley_facemedium') format('svg');
    font-weight: 500;
    font-style: normal;

  }


  @font-face {
    font-family: 'smileyface';
    src: url('#{$path}/smileyface/smileyface-light-webfont.eot');
    src: url('#{$path}/smileyface/smileyface-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$path}/smileyface/smileyface-light-webfont.woff2') format('woff2'),
    url('#{$path}/smileyface/smileyface-light-webfont.woff') format('woff'),
    url('#{$path}/smileyface/smileyface-light-webfont.ttf') format('truetype'),
    url('#{$path}/smileyface/smileyface-light-webfont.svg#smiley_facelight') format('svg');
    font-weight: 300;
    font-style: normal;
  }


  @font-face {
    font-family: 'smileyface';
    src: url('#{$path}/smileyface/smileyface-bolditalic-webfont.eot');
    src: url('#{$path}/smileyface/smileyface-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$path}/smileyface/smileyface-bolditalic-webfont.woff2') format('woff2'),
    url('#{$path}/smileyface/smileyface-bolditalic-webfont.woff') format('woff'),
    url('#{$path}/smileyface/smileyface-bolditalic-webfont.ttf') format('truetype'),
    url('#{$path}/smileyface/smileyface-bolditalic-webfont.svg#smiley_facebold_italic') format('svg');
    font-weight: 700;
    font-style: italic;

  }


  @font-face {
    font-family: 'smileyface';
    src: url('#{$path}/smileyface/smileyface-bold-webfont.eot');
    src: url('#{$path}/smileyface/smileyface-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$path}/smileyface/smileyface-bold-webfont.woff2') format('woff2'),
    url('#{$path}/smileyface/smileyface-bold-webfont.woff') format('woff'),
    url('#{$path}/smileyface/smileyface-bold-webfont.ttf') format('truetype'),
    url('#{$path}/smileyface/smileyface-bold-webfont.svg#smiley_facebold') format('svg');
    font-weight: 700;
    font-style: normal;

  }

}

@mixin additional-style-headline($path){
  @font-face {
    font-family: 'smileyface_headline';
    src: url('#{$path}/smileyface/smileyface-headline-webfont.eot');
    src: url('#{$path}/smileyface/smileyface-headline-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$path}/smileyface/smileyface-headline-webfont.woff2') format('woff2'),
    url('#{$path}/smileyface/smileyface-headline-webfont.woff') format('woff'),
    url('#{$path}/smileyface/smileyface-headline-webfont.ttf') format('truetype'),
    url('#{$path}/smileyface/smileyface-headline-webfont.svg#smiley_faceheadline') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}

@mixin separate($path){
  @error("SmileyFace does not have separate implemented");

}
